
import { BlogPostStatus, CreateBlogModel } from 'tradingmate-modules/src/models/api/blogs'
import { Services } from 'tradingmate-modules/src/services'
import { Component, Mixins } from 'vue-property-decorator'

import { FormPage } from '@/mixins'
import { InputDateTime, InputText, InputTextarea } from '@/components/inputs'
import ContentHtmlInput from '@/components/inputs/ContentHtmlInput.vue'
import BlogType from 'tradingmate-modules/src/models/api/blogs/BlogType'

@Component({
  components: {
    ContentHtmlInput,
    InputText,
    InputDateTime,
    InputTextarea
  }
})
export default class BlogCreate extends Mixins(FormPage) {
  private readonly dateConfig = { minDate: Date.now() }

  private createBlogModel: CreateBlogModel = {
    BusinessBranchId: this.$store.state.identity.BusinessBranch.BusinessBranchId,
    ExpirationDate: undefined,
    Status: BlogPostStatus.Draft,
    Heading: '',
    Subheading: '',
    ExternalLink1: '',
    ExternalLink2: '',
    Content: '',
    BlogType: BlogType.Blog
  }

  savePost (): void {
    this.loading = true

    Services.API.Blogs.CreateBlog(this.createBlogModel)
      .then((blog) => {
        this.httpError = null
        this.$router.replace(`/blog/${blog.Slug}`)
      }).catch((error) => {
        this.httpError = error
      }).finally(() => {
        this.loading = false
        window.scroll({ top: 0, left: 0, behavior: 'smooth' })
      })
  }
}
